import React from "react";
import styled from "styled-components";
import Device from "device";

const AboutText = styled.div`
  blockquote {
    position: relative;
    font-style: italic;
    padding: 30px 0;
    margin: 20px 0 0 0;
    display: inline-block;
    quotes: "“" "”" "‘" "’";

    @media ${Device.laptop} {
      padding: 0;
    }

    &:before,
    &:after {
      font-size: 50px;
      font-weight: bold;
      position: absolute;
      opacity: 0.2;
      overflow: hidden;
      width: 40px;
      height: 40px;
      line-height: 40px;
    }

    &:before {
      content: open-quote;
      left: -15px;
      top: 15px;

      @media ${Device.laptop} {
        left: -40px;
        top: 0;
      }
    }
    &:after {
      content: close-quote;
      right: 30px;
      bottom: 0;

      @media ${Device.laptop} {
        right: -30px;
        bottom: -10px;
      }
    }
  }
`;

export const persons = [
  {
    name: "Иващенко Александр Николаевич",
    position: "Врач-стоматолог Хирург-имплантолог",
    photo: "/i/dentist/alex.jpg",
    thumb: "/i/dentist/alex_thumb.jpg",
    about: (
      <AboutText>
        <p>Профессиональный стаж более 10 лет</p>
        <ul>
          <li>
            2009г. - окончил Владивостокский Государственный Медицинский
            Университет.
          </li>
          <li>
            2010 г. - окончил интернатуру по стоматологии общей практики СПбМАПО
          </li>
          <li>
            2012 г. - окончил ординатуру по челюстно-лицевой хирургии СПбМАПО
          </li>
          <li>прошел специализацию по хирургической стоматологии СПбМАПО</li>
          <li>Посетил множество образовательных курсов в РФ и Европе.</li>
        </ul>
      </AboutText>
    ),
    abouttext: (
      <AboutText>
        <p>
          Специализируется на сложных удалениях зубов, зубосохраняющих
          операциях, дентальной имплантации, костно-пластических операциях,
          пластике и закрытии рецессий десны.
        </p>
        <blockquote>
          Я происхожу не из семьи медиков, но войдя в профессию сразу понял, что
          это мое! Мне нравится осознавать тот факт, что совершенствование врача
          не заканчивается никогда и, занимаясь хирургией, помимо отработанных
          до идеала вещей, всегда пробуешь что-то новое, конечно же, после
          тщательного усвоения материала, ведь развитие стоматологии не стоит на
          месте!
        </blockquote>
      </AboutText>
    ),
  },
  {
    name: "Вольковецкая Елена Николаевна",
    position: "Куратор лечения",
    photo: "/i/dentist/volkovecskaya.jpg",
    thumb: "/i/dentist/volkovecskaya_thumb.jpg",
    about: (
      <AboutText>
        <p>
          Ваш персональный менеджер, который помогает адаптировать процесс
          лечения к вашему графику, потребностям и финансовым возможностям.
        </p>
      </AboutText>
    ),
    abouttext: (
      <AboutText>
        <ul>
          <li>
            Рассказывает о клинике; сопровождает пациента в учреждении и
            отвечает на возникающие вопросы;
          </li>
          <li>
            Быстро и четко предоставит вам любую информацию по работе клиники,
            предлагаемым услугам, расписанию и специализации врачей и др.
          </li>
          <li>
            На основе разработанного лечащим врачом плана лечения составит
            финансовый план, поможет Вам подобрать условия оплаты, составить
            график платежей, поможет воспользоваться выгодными акциями.
          </li>
          <li>
            Проследит за правильным оформлением необходимых документов
            (например, договора, рассрочки платежей или справок для налогового
            вычета).
          </li>
          <li>
            Станет тактичным и грамотным посредником между пациентом и врачом
            при возникновении спорных ситуаций.
          </li>
          <li>
            Поможет воспользоваться гарантиями на лечение, имплантацию и другие
            услуги.
          </li>
        </ul>
        <blockquote>
          Приняв Ваши пожелания, я выстрою лечение по этапам и подберу удобный
          для Вас график посещения врачей, чтобы обеспечить слаженную работу
          всех специалистов – и, таким образом, наилучший результат лечения для
          Вас!
        </blockquote>
      </AboutText>
    ),
  },
  {
    name: "Кошкелян Артур Артурович",
    position: "Врач – стоматолог ортопед",
    photo: "/i/dentist/koshkelyan.jpg",
    thumb: "/i/dentist/koshkelyan_thumb.jpg",
    about: (
      <AboutText>
        <p>Профессиональный стаж более 5 лет</p>
        <ul>
          <li>2015г. - окончил СПбГМУ имени академика И.П.Павлова.</li>
          <li>
            2016г. - прошел обучение в интернатуре по стоматологии общей
            практики СПбГМУ имени академика И.П.Павлова.
          </li>
          <li>
            2016г. - прошел специализацию по ортопедической и терапевтической
            стоматологии.
          </li>
          <li>
            Регулярно посещает различные образовательные курсы у ведущих
            специалистов.
          </li>
          <li>Занимается лекторской деятельностью.</li>
        </ul>
      </AboutText>
    ),
    abouttext: (
      <AboutText>
        <p>
          Специализируется на лечении и восстановлении зубов с применением
          стоматологического микроскопа, протезировании с опорой на зубах и
          имплантатах. Ведет фотопротокол.
        </p>
        <blockquote>
          Я хочу считать себя перфекционистом в профессии. Не жалею ни времени
          ни сил, чтобы в итоге пациент получил лучший результат нашего лечения.
        </blockquote>
      </AboutText>
    ),
  },
  {
    name: "Штерцер Анастасия Романовна",
    position: "Врач – стоматолог терапевт",
    photo: "/i/dentist/shtercer.jpg",
    thumb: "/i/dentist/shtercer_thumb.jpg",
    about: (
      <AboutText>
        <p>
          Специализируется на лечении кариеса и его осложнений, а также
          эстетической реабилитации твёрдых тканей зубов под микроскопом, ведёт
          фотопротокол каждого приёма.
        </p>
      </AboutText>
    ),
    abouttext: (
      <AboutText>
        <p>
          <strong>Профессиональный стаж более 7 лет</strong>
        </p>
        <ul>
          <li>
            2015 г. - окончила Санкт-Петербургский Государственный Университет,
            факультет стоматологии и медицинских технологий
          </li>
          <li>
            2017 г. - окончила обучение в ординатуре СПбГУ по специальности
            «Стоматология терапевтическая»
          </li>
          <li>
            С 2016 года регулярно посещает курсы повышения квалификации у лучших
            лекторов в области эстетической реставрации зубов и лечения корневых
            каналов под микроскопом
          </li>
        </ul>
        <blockquote>
          Моим приоритетом является качественное лечение, а также комфорт
          пациента во время стоматологического приёма. Я и моя команда сделаем
          всё, чтобы пациенту было уютно в клинике, а также непосредственно в
          кресле во время манипуляций. Считаю важным, чтобы пациент понимал,
          какие процессы происходят в его организме, что именно мы выполняем и
          каков план дальнейших действий; только так мы вместе достигнем полного
          здоровья полости рта и сможем его поддерживать долгое время
        </blockquote>
      </AboutText>
    ),
    certificate: [
      {
        url: "/i/certificate/shtercer/C44D1358-D703-426F-B3E4-A1A04687683F.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/E859ADD7-B808-473C-AF99-058D336A6E8E.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/F2BDF448-00E0-469F-9EAA-030D7933DA5E.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/E25980BD-C722-4460-98BD-686A853191BC.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/C00BA8AA-8276-4AEF-BF88-DC54A96E2EC8.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/A9A5EE7C-6E04-49A5-BA69-20A7D3C84F4B.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/3398EDF2-57F8-47C2-A35D-FEA838E54448.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/1469CC1B-D6A8-431A-8E95-2908485B53E4.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/447F998A-020E-4743-9282-71D78AC7EA98.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/92B96CAF-87B1-4428-813E-2F6DB4FA77D0.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/57DC00D0-F22D-4AFC-97F4-BCF411861D09.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/40EF9307-DA65-4799-847F-59F1A42FF940.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/011E3FA3-04DC-435B-A329-AACB7AABF30E.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/8D7F67E0-D8A2-43FD-A804-4548C1CA431F.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/8A31E0D3-53DA-4934-9B4A-F1DC45497307.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/8A3BEC89-08D9-4607-922B-72092FACB5DF.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/6C6E00DB-D4A4-4DD5-8FB6-4A48AC40BB7A.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shtercer/2AD11353-A764-4F88-8FC6-C9A16402CCEF.jpg",
        title: "",
      },
    ],
  },
  {
    name: "Антоневич Виктор Викторович",
    position: "Врач – стоматолог терапевт",
    photo: "/i/dentist/antonevich.jpg",
    thumb: "/i/dentist/antonevich_thumb.jpg",
    about: (
      <AboutText>
        <p>Опыт работы 2 года</p>
        <ul>
          <li>
            2021 г. - окончил Северо-Западный государственный университет им
            И.И.Мечникова по специальности врач-стоматолог
          </li>
          <li>
            2023 г. - окончил обучение в ординатуре Первого Санкт-Петербургского
            государственного медицинского университета им. акад. И.П. Павлова по
            специальности «Стоматология общей практики»
          </li>
          <li>
            С 2018 г. посещает курсы по эстетической реставрации и
            эндодонтическому лечению корневых каналов зубов.
          </li>
          <li>
            С 2021 г. ведёт практические занятия у студентов-стоматологов
            младших курсов.
          </li>
        </ul>
      </AboutText>
    ),
    abouttext: (
      <AboutText>
        <p>
          Специализируется на лечении кариеса и его осложнений, эстетическом
          восстановлении зубов под стоматологическим микроскопом, ведёт
          поэтапный фотопротокол на каждом приёме.
        </p>
        <blockquote>
          <p>
            Стоматолог любого профиля – это врач! Врач, который несёт
            ответственность за здоровье пациента, а я, как врач-стоматолог, не
            только несу большую ответственность за здоровье пациента, но и хочу
            улучшить качество его жизни.
          </p>
          <p>
            Я считаю, что любая работа должна быть выполнена качественно от
            начала до конца и выглядеть эстетично.
          </p>
          <p>
            Желание действительно помочь, сделать жизнь людей лучше - стало моим
            стимулом к развитию в стоматологии.
          </p>
          <p>
            Я потрачу столько сил и времени на каждого пациента - сколько будет
            нужно для достижения наилучшего результата.
          </p>
          <p>
            „Имейте в виду, если вы сделаете быстро и плохо, то люди забудут,
            что вы сделали быстро, и запомнят, что вы сделали плохо. Если вы
            сделаете медленно и хорошо, то люди забудут, что вы сделали
            медленно, и запомнят, что вы сделали хорошо!“ — Сергей Павлович
            Королёв
          </p>
        </blockquote>
      </AboutText>
    ),
  },
  {
    name: "Пронченко Ульяна Сергеевна",
    position: "Врач – стоматолог ортопед",
    photo: "/i/dentist/pronchenko.jpg",
    thumb: "/i/dentist/pronchenko_thumb.jpg",
    about: null,
    abouttext: (
      <AboutText>
        <ul>
          <li>
            2010 г. - Окончила Санкт-Петербургский государственный медицинский
            университет им.ак.И.П. Павлова по специальности «Стоматология».
          </li>
          <li>
            2011 г. - Интернатура по специальности Санкт-Петербургский
            государственный медицинский университет им.ак.И.П. Павлова.
          </li>
        </ul>
        <h3>Дополнительное образование:</h3>
        <div>
          <p>2012 год:</p>
          <p>
            «Применение ультраниров в практике ортопедического
            стоматологического лечения»; «Протезирование культевыми штифтовыми
            вкладками»; «Восстановление дефектов твердых тканей зубов и дефектов
            зубных рядов металлокерамическими зубными протезами».
          </p>
          <p>2013 год:</p>
          <p>
            «Бюгельные протезы с использованием кламмерных, замковых и фрезерных
            конструкций»; «Протезирование при полной потери зубов (пути
            повышения эффективности ортопедического лечения)»; «Безметалловые
            конструкции зубных протезов при нарушении эстетике, дефектов твердых
            тканей зубов и зубных рядов».
          </p>
          <p>2015 г. - «Основы клинической гнатологии».</p>
          <p>2016 г. - «Клинические аспекты протезирования на имплантатах».</p>
          <p>2017 год:</p>
          <p>
            «Особенности протезирования пациентов при частичной и полной потери
            зубов с опорой на имплантаты»;
          </p>
          <p>
            «Ошибки и осложнения ортопедического лечения конструкциями,
            опирающимися на дентальные имплантаты»;
          </p>
          <p>«Планирование и препарирование», Гаджи Дажаев;</p>
          <p>
            «Грамматика реконструктивного протезирования на примере полного
            съёмного протезирования»;
          </p>
          <p>
            «Реабилитация пациентов с полной адентией с использованием
            дентальных имплантатов»;
          </p>
          <p>
            «Интегрированный подход к достижению идеального эстетического
            результата на имплантантах»;
          </p>
          <p>
            «Протезирование при полном отсутствии зубов в сложных клинических
            ситуациях».
          </p>
          <p>
            2020 г. - «Эстетические и функциональные непрямые реставрации
            фронтальных зубов - керамические виниры».
          </p>
        </div>
        <blockquote>
          Для меня очень важно работать в команде профессионалов, без этого
          никак, один ортопед не сможет привести пациента к результату без
          талантливых коллег. Большая удача - иметь возможность использовать
          новейшие технологии в области ортопедии. Без сомнения, важно
          взаимопонимание между пациентом и доктором. А результаты своих трудов
          я люблю наблюдать в счастливой улыбке пациентов.
        </blockquote>
      </AboutText>
    ),
    certificate: [
      { url: "/i/certificate/pronchenkou/1.jpg", title: "" },
      { url: "/i/certificate/pronchenkou/2.jpg", title: "" },
      { url: "/i/certificate/pronchenkou/3.jpg", title: "" },
      { url: "/i/certificate/pronchenkou/4.jpg", title: "" },
      { url: "/i/certificate/pronchenkou/5.jpg", title: "" },
      { url: "/i/certificate/pronchenkou/6.jpg", title: "" },
      { url: "/i/certificate/pronchenkou/7.jpg", title: "" },
      { url: "/i/certificate/pronchenkou/8.jpg", title: "" },
      { url: "/i/certificate/pronchenkou/9.jpg", title: "" },
    ],
  },
  {
    name: "Штерцер Юрий Юрьевич",
    position: "Врач – стоматолог хирург – имплантолог",
    photo: "/i/dentist/shterceru.jpg",
    thumb: "/i/dentist/shterceru_thumb.jpg",
    about: (
      <AboutText>
        <ul>
          <li>
            С отличием окончил Санкт-Петербургский Государственный Университет
            по специальности «Стоматология» в 2015 году 
          </li>
          <li>
            Ординатура на кафедре челюстно-лицевой хирургии и хирургической
            стоматологии СПбГУ 2017 году 
          </li>
        </ul>
      </AboutText>
    ),
    abouttext: (
      <AboutText>
        <p>
          Специализируется на сложных удалениях зубов, имплантации, костной и
          мягкотканной пластике и других видах хирургического лечения.
        </p>
        <p>
          Постоянно занимается повышением своей квалификации, посещая различные
          учебные мероприятия как в России, так и за рубежом. Занимается научной
          работой на тему предотвращения и лечения осложнений при дентальной
          имплантации в рамках написания кандидатской диссертации в аспирантуре
          СПбГУ.
        </p>
        <blockquote>
          Для меня как для хирурга в лечении пациентов главное –
          прогнозируемость результата
        </blockquote>
      </AboutText>
    ),
    certificate: [
      {
        url: "/i/certificate/shterceru/FDB81D62-3608-491F-9A1E-D08956E8328C.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/FAB3F819-90CC-461D-B8BA-E95844DF1A82.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/F7145B0B-2826-450E-9223-BD86559D60D4.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/F899CBD2-3579-46AC-B50C-B1D3C9A72F99.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/F63DFA06-A7C3-4DB5-AA83-3B0A46C0C607.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/F5B1A865-553C-4CA4-A8BC-0786499DC24B.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/D9AD5538-8EB2-42FB-9206-6A62C1C9CBF5.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/CBB9295E-227E-4F54-9C17-B71219BC19CE.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/B800F59D-EFAF-4227-80C8-D8D54010B787.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/AA890C20-02E0-4334-9E45-67C031573763.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/A4F63535-892C-49F4-930A-7D1EB74796F7.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/95714D6B-DD33-49D6-8522-DAC4D7C5E3D5.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/8119D565-6236-4AFD-89F1-0713DC70789D.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/05128A55-0CB3-4B24-A5E9-380AE712188A.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/3101C3C3-36B6-40BE-836A-7ACBFB8D94FA.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/961D388F-6556-4F04-956B-851E55AB3D05.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/470E3017-872D-44C8-8805-6FF989C8E880.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/232C5212-76B7-4EFF-AC9F-8AAE0F49C828.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/73F2A186-C106-4A51-9933-E7217D7E7CAB.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/36CB9AAD-13E1-4D19-BC05-AC3496F6C76B.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/36A197D6-4527-4B15-898D-46328F425383.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/22E427EC-3FE9-41CB-B620-CDEF4A7393A4.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/8F310B85-229B-4BB4-B25B-4CFC70256D99.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/7BAAFAC2-E79F-4743-8946-62998C6B2E36.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/7B404203-0160-4B48-ABC6-E03655E0DF17.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/2EDE0661-0652-414E-851C-E8E3C7B2CE16.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/1C9EC568-CBB7-43B6-B179-6A37D6518298.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/0F97258A-AD21-4362-89D5-6535B5B9B80E.jpg",
        title: "",
      },
      {
        url: "/i/certificate/shterceru/0DB07FFC-42CD-45D9-A054-713BD73722E6.jpg",
        title: "",
      },
    ],
  },
];
