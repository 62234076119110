import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import Device from "device";
import Button from "components/Button";
import ReactCompareImage from "react-compare-image";

import { Modal } from "components/Modal/Modal";
import FormModal from "components/Modal/FormModal";

import "swiper/swiper.min.css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import { Keyboard, Scrollbar, Navigation } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import AppContext from "contexts/AppContext";

import ym from "react-yandex-metrika";

import { ReactComponent as SvgLogo } from "assets/icons/logo_symbol.svg";
import { ReactComponent as SvgMenu } from "assets/icons/icon_menu.svg";

import { portfolio } from "content/portfolio";

const Wrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  @media ${Device.laptop} {
    flex-direction: row;
  }
`;
const Header = styled.header`
  display: flex;
  padding: 20px;
  transform: translate(0, 100%);
  opacity: 0;
  transition: all 0.2s 0s;
  box-shadow: 0 10px 15px 15px rgba(255, 255, 255, 1);
  z-index: 2;
  background: #fff;

  @media ${Device.laptop} {
    padding: 80px 100px 0 0;
  }

  &.visible {
    opacity: 1;
    transform: translate(0, 0);
  }
`;
const Body = styled.section`
  padding: 20px;
  opacity: 0;
  transition: all 0.2s 0.4s;
  transform: scale(0.9);
  max-height: 100%;
  overflow: auto;

  @media ${Device.laptop} {
    padding: 0;
  }

  &.visible {
    opacity: 1;
    transform: scale(1);
  }
`;
const Logo = styled(SvgLogo)`
  width: 30px;
  min-width: 30px;
  height: 40px;
  fill: ${(props) => props.theme.color.dark};
  transform: translate(-100%, 0);
  pointer-events: none;
  opacity: 0;
  margin: 0 15px 0 0;
  transition: all 0.2s 0.2s;
  position: relative;
  top: -5px;

  @media ${Device.laptop} {
    width: 60px;
    min-width: 60px;
    height: 120px;
    margin: 0 20px 0 0;
    top: -20px;
  }

  &.visible {
    opacity: 1;
    transform: translate(0, 0);
  }
`;
const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  line-height: normal;
  color: ${(props) => props.theme.color.dark};
  display: inline-block;

  @media ${Device.laptop} {
    font-size: 36px;
  }
`;
const Closebutton = styled.button`
  border: none;
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 0;
  margin: 0;
  z-index: 999;
  opacity: 0;
  background: rgba(255, 255, 255, 0.5);
  color: #222;
  border-radius: 100px;

  &.visible {
    opacity: 1;
  }
  &:hover {
    background: #fff;
  }

  @media ${Device.laptop} {
    top: 40px;
    right: 40px;
  }
`;
const IconMenu = styled(SvgMenu)`
  width: 30px;
  height: 30px;
  stroke: currentColor;
  cursor: pointer;
  transition: all 0.1s;
  margin: -1px 0 0 -2px;

  @media ${Device.laptop} {
    width: 50px;
    height: 50px;
    margin: 0;
  }
`;
const Cover = styled.div`
  width: 100%;
  height: 300px;
  min-height: 300px;
  background-color: #eee;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  z-index: 3;

  @media ${Device.laptop} {
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    bottom: 0;
    width: auto;
    height: auto;
  }
`;
const Btn = styled(Button)`
  width: auto;
  margin: 30px 0 0 0;
  text-align: center;
`;
const ContactsWrap = styled.div`
  padding: 20px;
  margin: 40px 0 0 0;
  background: #f7f7f7;
  border-radius: 4px;
  font-family: "Source Sans Pro", sans-serif;
`;
const Content = styled.div`
  width: 100%;

  @media ${Device.laptop} {
    max-width: 50%;
    padding: 40px;
  }
`;
const PortfolioMain = styled.div`
  width: 100%;
  padding: 0;
  min-height: 200px;

  .swiper {
    @media ${Device.laptop} {
      max-height: 100%;
    }
  }
  .swiper-slide {
    @media ${Device.laptop} {
      height: -webkit-max-content !important;
      height: -moz-max-content !important;
      height: max-content !important;
    }
  }
  .swiper-container {
    @media ${Device.laptop} {
      max-height: 100%;
    }
  }

  @media ${Device.laptop} {
    max-width: 50%;
    padding: 40px;
    height: 100vh;
    background: #eee;
  }
`;
const StyledSwiper = styled(Swiper)`
  padding: 0;
  margin: 0;
`;
const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  user-select: none;
  flex-wrap: wrap;

  @media ${Device.laptop} {
    flex-wrap: wrap;
  }
`;
const SliderTitle = styled.h3`
  width: 100%;
  margin: 0 0 20px 0;
  font-size: 24px;
  color: ${(props) => props.theme.color.dark};

  @media ${Device.laptop} {
    color: #000;
  }
`;
const Images = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  max-height: 100%;

  @media ${Device.laptop} {
    flex-wrap: wrap;
  }
`;
const Text = styled.div`
  padding: 0;
  margin: 0 0 20px 0;
`;
const About = styled.div`
  font-size: 14px;
  line-height: normal;
  color: ${(props) => props.theme.color.dark};

  @media ${Device.laptop} {
    font-size: 16px;
    color: #000;
  }
`;
const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Item = styled.div`
  width: 60px;
  height: 60px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: pointer;
  border: 3px solid #fff;
  margin: 0 15px 15px 0;

  @media ${Device.laptop} {
    width: 100px;
    height: 100px;
  }

  &.active {
    border-color: ${(props) => props.theme.color.cyan_hover};
  }
`;

const PortfolioModal = (props) => {
  const { setModal } = useContext(AppContext);
  const [isDone, setIsDone] = useState(false);
  const [isFade, setIsFade] = useState(false);
  const sliderRef = useRef(null);
  const [sl, setSl] = useState(0);

  const { data } = props;

  function handleClosemodal() {
    setIsFade(true);
    setTimeout(() => setModal(null), 800);
  }

  function scrollToRef(ref) {
    ref.current.scrollIntoView({
      behavior: "smooth",
    });
  }

  function handleSliderChange(id) {
    if (id === sl) {
      return;
    }
    setSl(id);
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideTo(id);
  }
  function sliderChange(id) {
    if (id === sl) {
      return;
    }
    setSl(id);
  }

  useEffect(() => {
    setTimeout(() => setIsDone(true), 400);

    if (props.type === "person") {
      ym("reachGoal", "openPerson", { name: props.title });
    }
    if (props.type === "service") {
      ym("reachGoal", "openService", { title: props.title });
    }
  }, []);

  return (
    <Modal
      textAlign="center"
      modalWidth={"max flex-direction-row " + (isFade ? "fade" : "")}
      padding="0"
    >
      <Closebutton
        className={isDone ? "visible cross" : "hidden"}
        onClick={handleClosemodal}
      >
        <IconMenu />
      </Closebutton>
      <Wrap>
        <PortfolioMain>
          {data.items ? (
            <StyledSwiper
              ref={sliderRef}
              slidesPerView={1}
              centeredSlides={false}
              spaceBetween={15}
              allowTouchMove={false}
              simulateTouch={false}
              onSlideChange={(swiperCore) => {
                const { activeIndex, snapIndex, previousIndex, realIndex } =
                  swiperCore;
                sliderChange(activeIndex);
              }}
              keyboard={{
                enabled: true,
              }}
              modules={[Keyboard, Navigation]}
              className="portfolio"
            >
              {data.items.map((item, index) => (
                <StyledSwiperSlide key={index}>
                  <Images>
                    <ReactCompareImage
                      aspectRatio={"taller"}
                      leftImage={item.before}
                      rightImage={item.after}
                      leftImageLabel={"До"}
                      rightImageLabel={"После"}
                      handleSize={40}
                      sliderLineColor="#163D5C"
                    />
                  </Images>
                </StyledSwiperSlide>
              ))}
            </StyledSwiper>
          ) : null}
        </PortfolioMain>
        <Content>
          <Body className={isDone ? "visible" : "hidden"}>
            <Text>
              {data.title ? <SliderTitle>{data.title}</SliderTitle> : null}
              {data.about ? <About>{data.about}</About> : null}
            </Text>
            {data.items ? (
              <List>
                {data.items.map((item, index) => (
                  <Item
                    key={index}
                    style={{ backgroundImage: `url(${item.after})` }}
                    onClick={() => handleSliderChange(index)}
                    className={sl === index ? "active" : ""}
                  />
                ))}
              </List>
            ) : null}
            <Text>
              <Button
                className="invert"
                onClick={() =>
                  setModal(<FormModal name={data.title} type={"service"} />)
                }
              >
                Запись на прием
              </Button>
            </Text>
          </Body>
        </Content>
      </Wrap>
    </Modal>
  );
};

export default PortfolioModal;
