import { createGlobalStyle } from "styled-components";
import Device from "device";
import "assets/fonts/clearsans.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    vertical-align: top;

    &::selection {
      background: ${(props) => props.theme.color.dark};
      color: #fff;
    }
  }
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;

    --swiper-theme-color: #5fa2d0;
  }
  body {
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    color: #222;
    font-family: "Clear Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.modal-open {
      overflow: hidden;
    }
  }
  #root {
    display: flex;
    width: 100%;
  }
  #root,
  .routerwrap {
    min-height: 100%;
    flex: 1;
  }
  .routerwrap {
    flex: 1;
    max-width: 100%;
  }
  h1, h2, h3, h4 {
    user-select: none;
  }
  h2 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    margin-top: 0;

    span {
      font-weight: 800;
    }

    @media ${Device.tablet} {
      font-size: 32px;
      line-height: normal;
    }
    @media ${Device.laptop} {
      font-size: 36px;
      line-height: 46px;
    }
  }
  p {
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 10px 0;

    @media ${Device.tablet} {
      font-size: 16px;
      line-height: 24px;
    }
  }
  a {
    color: ${(props) => props.theme.color.dark};
  }
  button {
    appearance: none;

    & + button {
      margin-left: 15px;

      @media ${Device.tablet} {
        margin-left: 20px;
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;

    li {
      position: relative;
      padding: 10px 5px 10px 30px;

      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background: url('/i/icon-right.svg');
        background-size: 20px auto;
        background-position: center;
        left: 0;
        top: 10px;
        transition: all .4s;
      }

      &:hover {
        &:after {
          left: 2px;
        }
      }
    }
  }
  input {
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.4);
    padding: 15px 0;
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    width: 100%;
    appearance: none;
    border-radius: 0;
    font-family: "Helvetica Neue", sans-serif;

    &::-webkit-contacts-auto-fill-button {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
    }

    @media ${Device.tablet} {
      padding: 10px 0;
    }

    &:focus {
      outline: 0;
    }
  }
  .slick-arrow {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    opacity: 1;
    z-index: 3;
    fill: #fff;
    transition: all 0.2s;
    top: auto;
    bottom: 50%;
    margin-top: -12px;

    @media ${Device.tablet} {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      bottom: 10px;
      margin-top: 0;
    }

    svg {
      transform: scale(1);
      transition: all 0.2s;
    }

    &:before,
    &:after {
      display: none;
    }

    &:hover {

    }
  }
  .slick-prev {
    left: 20px;
    transform: translateX(0px);

    &:hover {
      transform: translateX(-4px);
    }
    &:active {
      transform: translateX(-4px) scale(0.8);
    }
  }
  .slick-next {
    right: 20px;
    transform: rotate(180deg) translateX(0px);

    &:hover {
      transform: rotate(180deg) translateX(-4px);
    }
    &:active {
      transform: scale(0.8) rotate(180deg) translateX(-4px);
    }
  }
  .slick-dots {
    li {
      width: auto;
      height: auto;

      &:after {
        display: none;
      }

      button {
        width: 12px;
        height: 12px;
        padding: 0;

        &:before {
          line-height: 12px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .slick-slide {
    overflow: hidden;
  }
  .digits {
    font-family: ${(props) => props.theme.fonts.digits};
  }
  .rub {
    font-family: "Helvetica Neue", sans-serif;
  }

  .swiper-horizontal > .swiper-scrollbar,
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    background: rgba(0, 0, 0, 0.1);
    height: 6px;
  }
  .swiper-scrollbar {
    .swiper-scrollbar-drag {
      height: 6px;
      border-radius: 6px;
      background-color: #5fa2d0;
    }
  }
`;

export default GlobalStyles;
