export const portfolio = [
  {
    title: "Allon4",
    about: "",
    items: [
      {
        before:
          "/i/beforeafter/allon4/053307B9-F6CD-4062-9ADC-9AFC143A965D.jpg",
        after: "/i/beforeafter/allon4/A1D0C5EC-5094-4778-8A1B-E8A021828B1B.jpg",
      },
      {
        before: "/i/beforeafter/allon4/2-1.jpg",
        after: "/i/beforeafter/allon4/2-2.jpg",
      },
      {
        before:
          "/i/beforeafter/allon4/5293BB80-DA18-4065-B21D-7ADD6ED257CE.jpg",
        after: "/i/beforeafter/allon4/A9F2E27A-F670-4DDD-8F2E-015A6F9DEA86.jpg",
      },
    ],
  },
  {
    title: "Элайнеры",
    about:
      "Ортодонтическое лечение направлено на восстановление гармонии прикуса и красоты улыбки. Наши специалисты обладают навыками по скорейшему получению надежного результата при лечении как на брекетах, так и на более эстетичных и комфортных элайнерах.",
    items: [
      {
        before: "/i/beforeafter/aligners/1-1.jpg",
        after: "/i/beforeafter/aligners/1-2.jpg",
      },
      {
        before: "/i/beforeafter/aligners/2-1.jpg",
        after: "/i/beforeafter/aligners/2-2.jpg",
      },
    ],
  },
  {
    title: "Лечение кариеса",
    about:
      "Лечение кариеса включает не только избавление от собственно кариеса, но и восстановление функции и эстетики зуба. В нашей клинике при помощи микроскопа и современных материалов врачи воссоздают зубы с высокой точностью, уделяя внимание мелочам.",
  },
  {
    title: "Виниры",
    about:
      "Восстановление формы и коррекция цвета и эстетики передних зубов возможна при помощи фиксации керамических виниров. Для изготовления ультратонких реставраций требуется минимальное препарирование зубов, а в некоторых случаях можно обойтись и вовсе без этого. Керамические виниры постоянны в форме и цвете, лучезарная улыбка обеспечена!",
    items: [
      {
        before: "/i/beforeafter/veneers/1-1.jpg",
        after: "/i/beforeafter/veneers/1-2.jpg",
      },
      {
        before: "/i/beforeafter/veneers/2-1.jpeg",
        after: "/i/beforeafter/veneers/2-2.jpeg",
      },
      {
        before: "/i/beforeafter/veneers/3-1.jpeg",
        after: "/i/beforeafter/veneers/3-2.jpeg",
      },
      {
        before: "/i/beforeafter/veneers/4-1.jpg",
        after: "/i/beforeafter/veneers/4-2.jpg",
      },
    ],
  },
  {
    title: "Кариес",
    about:
      "Лечение кариеса включает не только избавление от собственно кариеса, но и восстановление функции и эстетики зуба. В нашей клинике при помощи микроскопа и современных материалов врачи воссоздают зубы с высокой точностью, уделяя внимание мелочам.",
    items: [
      {
        before: "/i/beforeafter/caries/1-1.jpg",
        after: "/i/beforeafter/caries/1-2.jpg",
      },
      {
        before: "/i/beforeafter/caries/2-1.jpg",
        after: "/i/beforeafter/caries/2-2.jpg",
      },
      {
        before: "/i/beforeafter/caries/3-1.jpg",
        after: "/i/beforeafter/caries/3-2.jpg",
      },
      {
        before: "/i/beforeafter/caries/4-1.jpg",
        after: "/i/beforeafter/caries/4-2.jpg",
      },
      {
        before: "/i/beforeafter/caries/5-1.jpg",
        after: "/i/beforeafter/caries/5-2.jpg",
      },
      {
        before: "/i/beforeafter/caries/6-1.jpg",
        after: "/i/beforeafter/caries/6-2.jpg",
      },
      {
        before: "/i/beforeafter/caries/7-1.jpg",
        after: "/i/beforeafter/caries/7-2.jpg",
      },
    ],
  },
  {
    title: "Полное протезирование",
    about:
      "В случае полной утраты зубов на верхней и нижней челюсти мы готовы восстановить жевательную функцию и эстетику. Полное протезирование при помощи съемных протезов – это компромиссный вариант при наличии противопоказаний к имплантации.",
    items: [
      {
        before: "/i/beforeafter/prosthetics/1-1.jpg",
        after: "/i/beforeafter/prosthetics/1-2.jpg",
      },
    ],
  },
  {
    title: "Протезирование на имплантатах",
    about:
      "Протезирование с опорой на имплантат – вариант выбора с условиях отсутствия зуба. Наши хирурги и ортопеды помогут быстро и эстетично восполнить утраченное, не испытывая боли и страхов",
    items: [
      {
        before: "/i/beforeafter/implants/1-1.jpg",
        after: "/i/beforeafter/implants/1-2.jpg",
      },
      {
        before: "/i/beforeafter/implants/2-1.jpg",
        after: "/i/beforeafter/implants/2-2.jpg",
      },
      {
        before: "/i/beforeafter/implants/3-1.jpg",
        after: "/i/beforeafter/implants/3-2.jpg",
      },
      {
        before: "/i/beforeafter/implants/4-1.jpg",
        after: "/i/beforeafter/implants/4-2.jpg",
      },
      {
        before: "/i/beforeafter/implants/5-1.jpg",
        after: "/i/beforeafter/implants/5-2.jpg",
      },
    ],
  },
  {
    title: "Тотальное протезирование",
    about:
      "Тотальное протезирование – это не только работа с запросом на эстетику, но и возвращение к полному функциональному использованию зубочелюстной системы, возвращение к гармонии и симметрии",
    items: [
      {
        before: "/i/beforeafter/total/1-1.jpg",
        after: "/i/beforeafter/total/1-2.jpg",
      },
      {
        before: "/i/beforeafter/total/2-1.jpg",
        after: "/i/beforeafter/total/2-2.jpg",
      },
      {
        before: "/i/beforeafter/total/3-1.jpg",
        after: "/i/beforeafter/total/3-2.jpg",
      },
      {
        before: "/i/beforeafter/total/4-1.jpg",
        after: "/i/beforeafter/total/4-2.jpg",
      },
      {
        before: "/i/beforeafter/total/5-1.jpg",
        after: "/i/beforeafter/total/5-2.jpg",
      },
    ],
  },
  {
    title: "Профгигиена",
    about:
      "Регулярная профессиональная чистка– залог здоровья полости рта, а также гарантия долгой службы реставраций и коронок. Наши специалисты проводят осмотры пациентов с частотой 1 раз в 6 месяцев, а при необходимости – осуществляют профгигиену.",
    items: [
      {
        before: "/i/beforeafter/hygiene/1-1.jpg",
        after: "/i/beforeafter/hygiene/1-2.jpg",
      },
    ],
  },
  {
    title: "Отбеливание",
    about:
      "Регулярная профессиональная чистка– залог здоровья полости рта, а также гарантия долгой службы реставраций и коронок. Наши специалисты проводят осмотры пациентов с частотой 1 раз в 6 месяцев, а при необходимости – осуществляют профгигиену.",
    items: [
      {
        before: "/i/beforeafter/whitening/1-1.jpg",
        after: "/i/beforeafter/whitening/1-2.jpg",
      },
    ],
  },
];
