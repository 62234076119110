import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Device from "device";
import Container from "components/Container";
import MenuItems from "components/MenuItems";
import { ReactComponent as SvgLogo } from "assets/icons/logo_horizont.svg";

import { createHistory } from "@reach/router";

import AppContext from "contexts/AppContext";

import ym from "react-yandex-metrika";

const Wrap = styled.section`
  position: relative;
  width: 100%;
  padding: 20px 0;
  transition: all 0.4s;
  z-index: 99;
  background: #fff;

  @media ${Device.laptop} {
    padding: 25px 0;
  }
`;
const Nav = styled.nav`
  display: none;

  @media ${Device.laptop} {
    display: block;
    transition: all 0.4s;
  }
`;
const LogoA = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const LogoWrap = styled.div``;
const Logo = styled(SvgLogo)`
  height: 21px;
  width: 88px;
  margin: 0 8px 0 0;
  fill: ${(props) => props.theme.color.dark};
  transition: all 0.4s;
  transform: translateY(-3px);

  @media ${Device.laptop} {
    height: 30px;
  }

  &.min {
    transform: scale(0.7);
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
`;
const Phone = styled.a`
  text-decoration: none;
  font-size: 16px;
  display: block;
  color: #000;
  line-height: 40px;
  transition: all 0.4s;
  margin: 0 60px 0 0;
  user-select: none;
  font-family: "Source Sans Pro", sans-serif;

  span {
    font-weight: bold;
  }

  @media ${Device.laptop} {
    font-size: 18px;
    line-height: 30px;
    margin: 0;
  }

  &:hover {
    color: #222;
  }
`;

const Topline = () => {
  const { config } = useContext(AppContext);
  const [ontop, setOntop] = useState(true);
  const url = createHistory(window);

  function handleScroll() {
    if (window.scrollY > 20) {
      setOntop(false);
    } else {
      setOntop(true);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <Wrap id="menu">
      <Container>
        <LogoA href={url.location.pathname === "/" ? "#" : "/"}>
          <LogoWrap>
            <Logo className={!ontop ? "min" : null} />
          </LogoWrap>
        </LogoA>
        <Phone
          href={`tel:${config.code}${config.phone}`}
          onClick={() => ym("reachGoal", "clickPhoneTop")}
        >
          {config.code}&nbsp;<span>{config.phone}</span>
        </Phone>
        {url.location.pathname === "/" ? (
          <Nav>
            <MenuItems />
          </Nav>
        ) : null}
      </Container>
    </Wrap>
  );
};

export default Topline;
