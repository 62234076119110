import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { createHistory, LocationProvider, Router } from "@reach/router";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import config from "./config";
import Metrika from "./metrika";

import GlobalStyles from "./globalStyles";
import Homepage from "./screens/Homepage";
import ThankYouPage from "./screens/ThankYouPage";
import NotFound from "./screens/NotFound";

// import CookieBanner from "./components/CookieBanner";

import AppContext from "contexts/AppContext";

import { YMInitializer } from "react-yandex-metrika";

const routerHistory = createHistory(window);

function App() {
  const [section, setSection] = useState(null);
  const [modal, setModal] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const paramsString = routerHistory.location.search;
  const locationParams = new URLSearchParams(paramsString);

  if (modal) {
    document.body.classList.add("modal-open");
  } else {
    document.body.classList.remove("modal-open");
  }

  const env = process.env.NODE_ENV;
  console.log("ENV: ", env);

  // Cookie
  //const cookie = localStorage.getItem("cookie");

  // dev
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const dev = params.get("dev");

  const appState = {
    config,
    section,
    setSection,
    setModal,
    menuOpen,
    setMenuOpen,
    locationParams,
    dev,
  };

  return (
    <ThemeProvider theme={theme}>
      {env != "production" ? (
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
      ) : null}
      <AppContext.Provider value={appState}>
        <YMInitializer
          accounts={[65349643]}
          options={{
            webvisor: true,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
          }}
          version="2"
        />
        <Metrika />
        <GlobalStyles />
        <LocationProvider history={routerHistory}>
          <Router className="routerwrap">
            <Homepage path="/" />
            <ThankYouPage path="/thank-you" />
            <NotFound default />
          </Router>
        </LocationProvider>
        {modal}
        {/* !cookie ? <CookieBanner /> : null */}
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
