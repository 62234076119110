import React, { Fragment, useContext } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-scroll";
import Device from "device";

import AppContext from "contexts/AppContext";
import ReviewModal from "components/Modal/ReviewModal";

const MenuItemCss = css`
  text-decoration: none;
  margin: 0 0 0 30px;
  padding: 0;
  cursor: pointer;
  position: relative;
  user-select: none;
  border: none;
  background: none;
  text-align: left;
  color: ${(props) => props.theme.color.dark};
  font-size: 16px;

  @media ${Device.laptop} {
    line-height: 30px;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    height: 2px;
    background: ${(props) => props.theme.color.dark};
    opacity: 0;
    transition: all 0.4s 0.2s;
    transform: scaleX(0);
  }
  &.active {
    &:after {
      opacity: 1;
      transform: scaleX(1);
    }
  }
`;
const NavItem = styled(Link)`
  ${MenuItemCss}
`;
const ReviewButton = styled.button`
  ${MenuItemCss}
`;

const MenuItems = () => {
  const { setSection, setMenuOpen, setModal, dev } = useContext(AppContext);

  function handleSetActive(to) {
    setSection(to);
    setMenuOpen(false);
  }
  return (
    <Fragment>
      <NavItem
        activeClass="active"
        to="menu"
        spy={true}
        smooth={true}
        duration={200}
        onSetActive={handleSetActive}
      >
        Главная
      </NavItem>
      <NavItem
        activeClass="active"
        to="service"
        spy={true}
        smooth={true}
        duration={200}
        onSetActive={handleSetActive}
      >
        Услуги и цены
      </NavItem>
      <NavItem
        activeClass="active"
        to="about"
        spy={true}
        smooth={true}
        duration={200}
        onSetActive={handleSetActive}
      >
        О клинике
      </NavItem>
      <NavItem
        activeClass="active"
        to="team"
        spy={true}
        smooth={true}
        duration={200}
        onSetActive={handleSetActive}
      >
        Команда
      </NavItem>
      <NavItem
        activeClass="active"
        to="portfolio"
        spy={true}
        smooth={true}
        duration={200}
        onSetActive={handleSetActive}
      >
        Портфолио
      </NavItem>
      <ReviewButton onClick={() => setModal(<ReviewModal />)}>
        Отзывы
      </ReviewButton>
      <NavItem
        activeClass="active"
        to="contacts"
        spy={true}
        smooth={true}
        duration={200}
        onSetActive={handleSetActive}
      >
        Контакты
      </NavItem>
    </Fragment>
  );
};

export default MenuItems;
