import styled from "styled-components";
import Device from "device";

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${(props) => props.theme.width.container};
  flex-wrap: wrap;
  z-index: 2;
  position: relative;

  @media ${Device.tablet} {
    padding: 0 30px;
  }
  @media ${Device.laptop} {
    padding: 0 15px;
  }

  &.justify-start {
    justify-content: flex-start;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.align-stretch {
    align-items: stretch;
  }
  &.full-width {
    max-width: 100%;

    @media ${Device.laptop} {
      padding: 0 40px;
    }
  }
`;
export default Container;
